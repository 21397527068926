#jumbotron {
  height: 90vh;
  background: url("photo-1528334713982-2d1fe11a157a.22843505.jpg") center / cover no-repeat;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#jumbotron > h2 {
  color: #fff;
  text-align: center;
}

#jumbotron > a {
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  font-size: 1.5em;
  text-decoration: none;
}

form {
  border: 1px solid #000;
  flex-direction: column;
  margin: 1rem;
  display: flex;
}

form > * {
  margin: .5rem;
}

form > input[type="submit"] {
  width: 40%;
  margin: .5rem auto .5rem .5rem;
}

footer {
  border-top: 1px solid #000;
  margin: 1rem;
  padding: 2rem 2rem 4rem;
}

#bio {
  margin: 1rem;
}

#pizzas {
  height: auto;
  width: 92.5%;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2em;
}

#pizzas tbody {
  width: 100%;
}

#pizzas th {
  text-align: left;
}

#pizzas td, th {
  width: 25%;
}

@media (max-width: 549px) {
  .hidden--mobile {
    display: none !important;
  }

  nav {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  nav > i {
    padding: 1rem;
    font-size: 2em;
  }

  nav > ul {
    flex-direction: column;
    align-items: center;
    padding: 0;
    display: flex;
  }

  nav > ul > li {
    padding: .5rem;
    list-style-type: none;
  }

  #bio img {
    float: left;
    width: 40%;
    height: auto;
    margin-right: 1rem;
  }
}

@media (min-width: 550px) {
  nav > ul {
    justify-content: space-evenly;
    padding: 0;
    display: flex;
  }

  nav > ul > li {
    border: 1px solid;
    border-radius: 25px;
    padding: .5rem;
    text-decoration: none;
    display: inline-block;
  }

  nav > .fa-bars {
    display: none;
  }

  #bio {
    margin-right: 3rem;
  }

  #bio img {
    float: left;
    width: 25%;
    height: auto;
    margin-right: 1rem;
  }
}

/*# sourceMappingURL=index.d6b0d291.css.map */
